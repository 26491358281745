import styled from '@emotion/styled'
import Link from 'next/link'
import { ReactNode } from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { RedirectUrls } from '../../types/types'

export const StyledLink = styled.a`
  text-decoration: none;
`

type ICardLink = {
  urlRoute: string
  children?: ReactNode
}

// This method allows us to render an anchor or link depending if it is desktop or not
// at LocationMap and LocationCard
const CardLocationLink = ({ urlRoute, children }: ICardLink) => {
  const { isUpMd } = useBreakpoints()

  if (isUpMd) {
    return (
      <StyledLink
        href={`${RedirectUrls.Details}/${urlRoute}`}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </StyledLink>
    )
  }

  return (
    <Link href={`${RedirectUrls.Details}/${urlRoute}`} passHref>
      {children}
    </Link>
  )
}

export default CardLocationLink
