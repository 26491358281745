import { IUser, Maybe } from 'src/types/graphql'

export function getCommercialProfile(
  user: Maybe<IUser> | undefined
): Maybe<IUser> | undefined {
  if (!user || !user.bussinessProfile) {
    return user
  }

  const newUser: IUser = {
    ...user,
    firstname: user.bussinessProfile.name,
    lastname: '',
    description: user.bussinessProfile.description,
    profilePicture: user.bussinessProfile.profilePicture
  }

  return newUser
}
