import { ILocation, Maybe } from 'src/types/graphql'

export function getCoverPhotoUrl(
  location: Maybe<ILocation> | undefined
): string {
  if (!location) {
    return '/images/empty.jpeg'
  }

  const { coverPhoto, photos } = location
  if (coverPhoto?.url) {
    return coverPhoto.url
  }
  if (photos && photos.length > 0 && photos[0].url) {
    return photos[0].url
  }
  return '/images/empty.jpeg'
}
