import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { InputMaybe, LocationType, Maybe, SizeFilter } from 'src/types/graphql'
import { mapCanStoreVehicleToRadio } from 'src/utils'

export default function useCommon() {
  const { t } = useTranslation('common')
  const locMap = t('LOCATION_TYPE', { returnObjects: true })
  const vehicleMap = t('CAN_STORE_VEHICLE', { returnObjects: true })
  const sizeMap = t('SIZE_FILTER', { returnObjects: true })

  const value = useMemo(() => {
    return {
      locMapValues: locMap,
      locMap: (locationType: Maybe<LocationType> | undefined) =>
        locationType ? locMap[locationType] : '',

      vehicleMapValues: vehicleMap,
      vehicleMap: (canStoreVehicle: InputMaybe<boolean> | undefined) =>
        vehicleMap[mapCanStoreVehicleToRadio(canStoreVehicle)],

      sizeMapValues: sizeMap,
      sizeMap: (size: Maybe<SizeFilter> | undefined) =>
        size ? sizeMap[size] : ''
    }
  }, [locMap, vehicleMap, sizeMap])

  return value
}
